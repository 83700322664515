import React, { useEffect, useState, useMemo } from "react";
import {
    FormWithRedirect,
    DateInput,
    TextInput,
    SelectInput,
    SaveButton,
    DeleteButton,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    email,
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    List,
    Datagrid,
    TextField,
    FormDataConsumer,
    FunctionField,
} from "react-admin";
import { Typography, Box, Chip } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageUploader from "../components/ImageUploader";
import { useNotify, useRedirect } from "react-admin";
import CustomSaveButton from './CustomSaveButton';
import { formatter } from "../../utils/formatter";
import { depositStatus } from "../../models/depositStatus";
import Pagination from '@material-ui/lab/Pagination';
import usePagination from "../../utils/pagination";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
}));

const DepositInfo = ({
    disbursement
}) => {

    const classes = useStyles();

    let disbursement2 = { "deposits": [{ "deposit_no": 1 }] };

    disbursement = disbursement != null ? disbursement : disbursement2;

    let [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const count = Math.ceil(disbursement.deposits.length / PER_PAGE);
    const _DATA = usePagination(disbursement.deposits, PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    return (
        <Card p="5">
            <CardContent>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.textBold} gutterBottom>
                        Deposit Payments
                    </Typography>
                </Grid>
                <Grid item xs={12}>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Deposit No</TableCell>
                                    <TableCell>Deposit Type</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Created Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FormDataConsumer>

                                    {({ formData, ...rest }) =>
                                        _DATA.currentData().map((row) => (
                                            <FormWithRedirect
                                                onSubmit={() => { }}
                                                render={(formProps) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            {formatter.depositNo(row.deposit_no)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.e_deposit_type?.comment}
                                                        </TableCell>
                                                        <TableCell>
                                                            {<Chip label={row.e_deposit_status?.comment} style={{ color: 'white', background: depositStatus.getColor(row.e_deposit_status?.id) }} />}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatter.formatMoney(row.amount, row.received_currency, 2)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.created_at != null ? moment(row.created_at).format("DD MMM YYYY hh:mm:ss A") : ""}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            />
                                        ))
                                    }
                                </FormDataConsumer>
                            </TableBody>
                        </Table>
                        <Pagination
                            count={count}
                            size="large"
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChange}
                            style={{ float: "right" }}
                        />
                    </TableContainer>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DepositInfo;
