import React from "react";
import { Button } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';

const LogoutButton = props => {
  const notify = useNotify();
  const [loading, setLoading] = useSafeSetState(false);

  const handleLogout = () => {
    setLoading(true);
    localStorage.removeItem('bleudine-user');
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    window.location.href = "/"
    setLoading(false);
  }

  return (
    <Button
      style={{ display:"-webkit-box", color: "#0000008a", marginLeft: 10, width: "100%" }}
      startIcon={<div style={{ display: "inline-flex", marginLeft: 12, marginRight: 10 }}><ExitToAppIcon /></div>}
      onClick={handleLogout}
    >
      Logout
    </Button>
  )
};

export default (LogoutButton);