export const depositStatus = {
  getColor,
};

function getColor(status) {
  switch (status) {
    case "Preauthorized":
      return "darkblue";
    case "Paid":
      return "darkgreen";
    case "Cancelled":
      return "darkred";
    default:
      return "white";
  }
}
