import React from 'react';
import { Edit } from 'react-admin';
import DisbursementEditForm from './DisbursementEditForm';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const DisbursementTitle = ({ record }) => {
  return <span>Disbursement</span>
};

const DisbursementEdit = props => (
  <div>
    <Breadcrumbs aria-label="breadcrumb" style={{
      borderRadius: 5,
      background: "white",
      padding: 12,
      border: "1px solid #e0e0e3",
      //marginLeft: 16,
      //marginRight: 30,
      marginTop: 12,
    }}>
      <Link color="inherit" href="/disbursements">
        Disbursements
      </Link>
      <Link style={{ fontWeight: "bold" }} color="textPrimary" aria-current="page" disabled>
        Disbursement
      </Link>
    </Breadcrumbs>
    <Edit title={<DisbursementTitle />} {...props} component="div">
      <DisbursementEditForm />
    </Edit>
  </div>
);


export default DisbursementEdit;
