import React, { useContext } from 'react';
import {
  SaveButton
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';


const CustomSaveButton = (formProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  var request = {
    ...values
  }

  const handleCreateClick = () => {
    setSaving(true);

    dataProvider.create(formProps.resource, { data: { ...request } })
      .then(({ data }) => {
          redirect(`/tenants`);
          notify("Tenants created");
      })
      .catch(error => {
          setSaving(false);
          notify(error);
      });
  }

  const handleUpdateClick = () => {
    setSaving(true);
    
    dataProvider.update(formProps.resource, { id: request.id, data: { ...request } })
      .then(({ data }) => {
        redirect(`/tenants`);
        notify("Tenants updated");
      })
      .catch(error => {
        setSaving(false);
        notify(error);
      })
  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;