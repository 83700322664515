import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const bleudineAuthApi = {
  login,
  resetPassword,
  changedPassword
};

function login(login,platformSite) { 
   const url = `${apiConfig.url}${apiConfig.path}/auth/login?platformSite=${platformSite}`;
   //const url = `https://localhost:54622/api/auth/login?platformSite=${platformSite}`;

   const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }), 
     body: JSON.stringify(login),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((login) => {
      return login;
    });
 }

 function resetPassword(email,platformType) { 
   const url = `${apiConfig.url}${apiConfig.path}/auth/resetpassword/${email}?platformType=${platformType}`;
  // const url = `https://localhost:54622/api/auth/resetpassword/${email}?platformType=${platformType}`;

   const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    })
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((reset) => {
      return reset;
    });
 }

 function changedPassword(newPassword) { 
   const url = `${apiConfig.url}${apiConfig.path}/auth/newpassword`;
  // const url = `https://localhost:54622/api/auth/newpassword`;

   const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json-patch+json",
    }), 
     body: JSON.stringify(newPassword),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((newPassword) => {
      return newPassword;
    });
 }