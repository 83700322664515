import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const tenantApi = {
getTenant,
deleteTenant
};

const url = apiConfig.rideumHotelUrl;

function getTenant(tenant_id) {
	var gql = {
    query: `query MyQuery {
      tenants(where: {id: {_eq: "${tenant_id}"}}) {
        name
        address_1
        address_2
        city
        country
        created_at
        email
        region
        phone_no
        status
        zip_code
        updated_at
        tenant_group_id
      }
    }   
    `,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}

function deleteTenant(tenant_id) {
	var gql = {
    query: `mutation MyMutation {
      update_tenants(where: {id: {_eq: "${tenant_id}"}}, _set: {is_deleted: true}) {
        returning {
          id
        }
      }
    }   
    `,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}