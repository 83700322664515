import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";
const localhostPort = 64638;

export const bleudineMerchantApi = {
getAll
};
function getAll() { 
  const url = `${apiConfig.url}${apiConfig.path}/merchants/list/bleustay/all`;
  //const url = `https://localhost:44312/api/merchants/list/bleustay/all`;

  const requestOptions = {
   method: "GET",
   headers: new Headers({
     Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
     "Content-Type": "application/json-patch+json",
   })
 };

 return fetch(url, requestOptions)
   .then(handleResponse, handleError)
   .then((merchant) => {
     return merchant;
   });
}


