import React from "react";
import {
  FormWithRedirect,
  TextInput,
  DateInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomSaveButton from './CustomSaveButton';

const validateName = [required(), minLength(2), maxLength(125)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const TenantForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Tenant Info
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="name"
                    fullWidth
                    validate={validateName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    resource="tenants"
                    source="bizRegDate"
                    type="date"
                    label="ra.column.bizRegDate"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="bizRegNo"
                    type="text"
                    label="ra.column.bizRegNo"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    resource="tenants"
                    source="email"
                    type="email"
                    label="ra.column.email"
                    fullWidth
                    validate={validateEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <IntlPhoneInput
                    className={classes.formInput}
                    resource="tenants"
                    source="phone_no"
                    label={translate("ra.column.phone_no")}
                    {...rest}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="country"
                    reference="e_countries"
                    sort={{ field: "comment", order: "ASC" }}
                    filterToQuery={(searchText) => ({
                      comment: searchText,
                    })}
                    label="ra.column.country"
                    validate={[required()]}
                    fullWidth
                  >
                    <AutocompleteInput
                      optionText="comment"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <ReferenceInput 
                    fullWidth 
                    className={classes.formInput} 
                    label="Currency" 
                    source="locale_id" 
                    reference="locales" 
                    validate={[required()]} 
                    disabled={
                      props.record?.name != null
                    }
                  >
                    <SelectInput optionText="currency_code" />
                  </ReferenceInput>
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Status"
                    source="status"
                    reference="e_tenant_status"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                {/* <SaveButton {...formProps} /> */}
                <CustomSaveButton {...formProps} />
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default TenantForm;
