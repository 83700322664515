import React, { Fragment } from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  Toolbar,
  useNotify,
  useRedirect,
  FormDataConsumer
} from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Box, Button } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";

import CustomSaveButton from './CustomSaveButton';
import { bleudineUserApi } from "../../api/bleudineUserApi";


const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const UserForm = props => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const { source, ...rest } = props;
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [selectedTenantGroupId, setSelectedTenantGroupId] = React.useState(null);
  const [selectedTenantId, setSelectedTenantId] = React.useState(null);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSelectedRoleChange = (event) => {
    setSelectedRole(event.target.value)
  };

  const handleSelectedTenantGroupIdChange = (event) => {
    if (event != "")
      setSelectedTenantGroupId(event.target.value)
    else
      setSelectedTenantGroupId(null)

  };

  const handleSelectedTenantIdChange = (event) => {
    if (event !== "")
      setSelectedTenantId(event.target.value)
    else
      setSelectedTenantId(null)
  };

  const handleConfirmDelete = (id) => {
    bleudineUserApi.deleteUser(id)
      .then((ret) => {
        redirect(`/users`);
        notify("User deleted");
      },
        (error) => {
          notify(error);
        });
  };

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <Card>
          {console.log(selectedTenantGroupId)}
          <form>
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    source="tenant_id"
                    label="Tenant ID"
                    fullWidth
                    validate={[required()]}
                  />
                  <TextInput
                    className={classes.formInput}
                    resource="users"
                    source="id"
                    label="ID"
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    fullWidth
                    label="Role"
                    source="role"
                    reference="e_user_roles"
                    validate={[required()]}
                    filter={{ comment: ["Manager", "Frontdesk", "Housekeeper"] }}
                    onChange={handleSelectedRoleChange}
                  >
                    <SelectInput optionText="comment" />
                  </ReferenceInput>
                </Grid>
                {selectedRole === "" ?
                  (
                    null
                  )
                  :
                  selectedRole !== "manager" ? (
                    selectedTenantGroupId === null ?
                      (
                        <Grid item xs={12} sm={6} className={classes.formGrid}>
                          <FormDataConsumer>
                            {({ formData, ...rest }) => (
                              formData.role !== "superhost" ?
                                <ReferenceInput
                                  className={classes.formInput}
                                  label="Merchant"
                                  source="tenant_groups_id"
                                  reference="tenant_groups"
                                  fullWidth
                                  resettable
                                  disabled={formData.id != null}
                                  onChange={handleSelectedTenantGroupIdChange}
                                >
                                  <SelectInput InputProps="id" optionText="name" />
                                </ReferenceInput> : <></>
                            )}
                          </FormDataConsumer>
                        </Grid>
                      )
                      :
                      (
                        <Fragment>
                          <Grid item xs={12} sm={6} className={classes.formGrid}>
                            <FormDataConsumer>
                              {({ formData, ...rest }) => (
                                formData.role !== "superhost" ?
                                  <ReferenceInput
                                    className={classes.formInput}
                                    label="Merchant"
                                    source="tenant_groups_id"
                                    reference="tenant_groups"
                                    fullWidth
                                    resettable
                                    disabled={formData.id != null}
                                    onChange={handleSelectedTenantGroupIdChange}
                                  >
                                    <SelectInput InputProps="id" optionText="name" />
                                  </ReferenceInput> : <></>
                              )}
                            </FormDataConsumer>
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.formGrid}>
                            <FormDataConsumer>
                              {({ formData, ...rest }) => (
                                formData.role !== "superhost" ?
                                  <ReferenceInput
                                    className={classes.formInput}
                                    label="Property"
                                    source="tenant_id"
                                    reference="tenants"
                                    fullWidth
                                    resettable
                                    disabled={formData.id != null}
                                    filter={{ tenant_group_id: selectedTenantGroupId }}
                                    onChange={handleSelectedTenantIdChange}
                                  >
                                    <SelectInput InputProps="id" optionText="name" />
                                  </ReferenceInput> : <></>
                              )}
                            </FormDataConsumer>
                          </Grid>
                        </Fragment>
                      )
                  ) :
                    <Grid item xs={12} sm={6} className={classes.formGrid}>
                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          formData.role !== "superhost" ?
                            <ReferenceInput
                              className={classes.formInput}
                              label="Merchant"
                              source="tenant_groups_id"
                              reference="tenant_groups"
                              fullWidth
                              resettable
                              disabled={formData.id != null}
                              onChange={handleSelectedTenantGroupIdChange}
                            >
                              <SelectInput InputProps="id" optionText="name" />
                            </ReferenceInput> : <></>
                        )}
                      </FormDataConsumer>
                    </Grid>

                }
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="users"
                    source="email"
                    type="email"
                    validate={validateEmail}
                    label="ra.column.email"
                    disabled={props.record.id !== undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="users"
                    source="first_name"
                    validate={validateFirstName}
                    label="ra.column.first_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="users"
                    source="last_name"
                    label="ra.column.last_name"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReferenceInput
                    className={classes.formInput}
                    label="Status"
                    source="status"
                    reference="e_user_status"
                    validate={[required()]}
                  >
                    <SelectInput optionText="comment" onClick={handleOpenDelete} />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {Object.keys(props.record).length === 0 ?
                  null :
                  <Button
                    style={{ color: "red" }}
                    variant="outline"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>}

              </Box>
              <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Delete?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm to delete
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleCloseDelete()} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleConfirmDelete(formProps.record.mapped_id)}
                    color="primary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  )
}

export default UserForm;