import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  DashboardMenuItem,
  MenuProps,
  ReduxState,
} from "react-admin";
import MenuItemLink from "../components/MenuItemLink.js";
import icInvoice from "../../images/ico_invoice.png";
import icUser from "../../images/ico_user.png";
import icCompany from "../../images/ico_company.png";
import SubMenu from "./SubMenu";

const Menu = ({ dense = false }) => {
  const [state, setState] = useState({
    menuBookings: true,
    menuConfigurations: true,
    menuCustomers: true,
    menuDisbursement: true,
  });
  const translate = useTranslate();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      style={{
        background: "white",
        borderRight: "1px solid #e0e0e3",
        paddingRight: 10,
        height: "100%",
      }}
    >
      <MenuItemLink
        to={{
          pathname: "/tenant_groups",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`ra.label.property_group`, {
          smart_count: 2,
        })}
        leftIcon={icUser}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/tenants",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`ra.label.properties`, {
          smart_count: 2,
        })}
        leftIcon={icCompany}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/users",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`ra.label.users`, {
          smart_count: 2,
        })}
        leftIcon={icUser}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/ibe_sites",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`ra.label.ibe_sites`, {
          smart_count: 2,
        })}
        leftIcon={icUser}
        dense={dense}
      />
      <MenuItemLink
        to={{
          pathname: "/disbursements",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`ra.label.disbursement`, {
          smart_count: 2,
        })}
        leftIcon={icInvoice}
        dense={dense}
      />
      {/* <MenuItemLink
        to={{
          pathname: "/cm_logging",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`ra.label.cm_logging`, {
          smart_count: 2,
        })}
        leftIcon={icUser}
        dense={dense}
      /> */}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
