import { buildFields } from "ra-data-hasura";
import { GET_LIST, GET_ONE } from "react-admin";
import { ResourceType, FetchType } from "ra-data-hasura";

import { GET_LIST_DISBURSEMENTS } from "./queries/disbursements";
import { GET_LIST_USERS, GET_ONE_USERS } from "./queries/users";
import { GET_LIST_TENANTS } from "./queries/tenants";
import { GET_LIST_CM_LOGGING } from "./queries/cmLogging";
import { GET_LIST_IBE_SITES } from "./queries/ibeSites";

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) =>
  queryAst.definitions[0].selectionSet.selections;

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES = {
  disbursements: {
    [GET_LIST]: GET_LIST_DISBURSEMENTS,
  },
  users: {
    [GET_LIST]: GET_LIST_USERS,
    [GET_ONE]: GET_ONE_USERS,
  },
  tenants: {
    [GET_LIST]: GET_LIST_TENANTS,
  },
  cm_logging: {
    [GET_LIST]: GET_LIST_CM_LOGGING,
  },
  ibe_sites: {
    [GET_LIST]: GET_LIST_IBE_SITES,
  },
};

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType);
};

export default customBuildFields;
