import gql from "graphql-tag";

export const GET_LIST_IBE_SITES = gql`
  {
    id
    name
    namespace
    logo_url
    banner_title_1
    banner_title_2
    banner_title_3
    banner_content_1
    banner_content_2
    banner_content_3
    tenant_group_id
    is_tenant_group
    tenant_group {
    id
    name
    email
    }
    fb_link
    banner_img_url_1
    banner_img_url_2
    banner_img_url_3
    tenant {
      id
      name
      email
    }
  }
`;
