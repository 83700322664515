export const paymentStatus = {
    getColor,
};

function getColor(status) {
    switch (status) {
        case "Success":
            return "darkgreen";
        case "Failed":
            return "darkred";
        case "Processing":
            return "darkblue";
        default:
            return "white";
    }
}