export const disbursementStatus = {
    getColor,
  };
  
  function getColor(status) {
    switch (status) {
      case "Pending":
        return "darkblue";
      case "Approved":
        return "darkgreen";
      case "Rejected":
        return "darkred";
      default:
        return "white";
    }
  }
  