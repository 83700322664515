import gql from 'graphql-tag';

const GET_LIST_DISBURSEMENTS = gql`
{
    disbursment_no
    bank_account_no
    bank_name
    charges
    currency
    created_at
    e_disbursement_status {
      comment
      id
    }
    id
    image_url
    invoice_payments {
      id
      invoice_id
      payment_no
      payment_transaction_id
      payment_type
      received_currency
      remarks
      status
      tenant_id
      amount
      created_at
      e_payment_status {
        comment
        id
      }
    }
    payable_amount
    remark
    status
    tenant_id
    updated_at
    tenant {
      name
    }
    deposits {
      amount
      booking_id
      created_at
      deposit_no
      disbursement_id
      e_deposit_status {
        comment
        id
      }
      e_deposit_type {
        comment
        id
      }
      id
      received_currency
      status
      tenant_id
      type
      updated_at
    }
}
`;

export { GET_LIST_DISBURSEMENTS }
