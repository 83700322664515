//import * as React from "react";
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
  ReferenceField
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { tenantStatus } from "../../models/tenantStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";
import { bleudineMerchantApi } from "../../api/bleudineMerchantApi";
import {useNotify } from 'ra-core';
import { tenantGroupApi } from "../../api/tenantGroupApi";

const tenantFilters = [
  <TextInput
    source="email,name,phone_no,region,city,country"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_tenant_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
  <ReferenceInput
    style={{ width: 250 }}
    label="Merchant"
    source="tenant_group_id"
    reference="tenant_groups"  
    alwaysOn
    resettable
  >
    <SelectInput optionText="name" /> 
  </ReferenceInput>,
];


const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      name: record.name,
      email: record.email,
      phone_no: record.phone_no,
      country: record.e_country.comment,
      status: record.e_tenant_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["name", "email", "phone_no", "country", "status"],
    },
    (err, csv) => {
      downloadCSV(csv, "tenants");
    }
  );
};

const TenantList = (props) => { 
  const history = useHistory();
  const notify = useNotify();
  const [mappedIds, setMappedIds] = useState([]);
  const [tenantGroupIds, setTenantGroupIds] = useState([]);

  useEffect(() => {
    bleudineMerchantApi.getAll().then(
      (merchants) => {
        merchants.forEach(merchant => {
          mappedIds.push(merchant.id)
        });

        tenantGroupApi.getTenantGroups(mappedIds).then(
          (tenantGroups) => {
            const tenantGroupIds = tenantGroups.data.tenant_groups.map(tenantGroup => tenantGroup.id);
            setTenantGroupIds(tenantGroupIds);
          },
          (error) => {
              notify(error);
          }
      );
      },
      (error) => {
          notify(error);
      }
  );
  },[]);

  return (
    <Fragment>
        {tenantGroupIds.length !== 0?
            <List
            sort={{ field: "created_at", order: "Desc" }}
            filters={tenantFilters}
            filterDefaultValues={{ is_deleted:false,tenant_group_id:tenantGroupIds }}
            bulkActionButtons={false}
            exporter={exporter}
            {...props}
          >
            <Datagrid>
              <TextField source="name" label="ra.column.name" />
              <EmailField source="email" label="ra.column.email" />
              <TextField source="phone_no" label="ra.column.phone_no" />
              <TextField source="locale.currency_code" label="ra.column.currency" />
              <TextField source="city" label="ra.column.city" />
              <TextField source="e_country.comment" label="ra.column.country" />
      
              <ReferenceField source="tenant_group_id" reference="tenant_groups" label="Merchants" link={false}>
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                sortBy="status"
                label="Status"
                render={(record) => (
                  <Chip
                    label={record.e_tenant_status?.comment}
                    style={{
                      color: "white",
                      background: tenantStatus.getColor(record.e_tenant_status?.id),
                    }}
                  />
                )}
              />
              <EditButton style={{ float: "right" }} basePath="/tenants" />
            </Datagrid>
          </List>
          : null}
    </Fragment>
  );
};

export default TenantList;
