import FaceIcon from "@material-ui/icons/Business";
import IbeSitesList from "./IbeSitesList";
import IbeSitesCreate from "./IbeSitesCreate";
import IbeSitesEdit from "./IbeSitesEdit";

export default {
  list: IbeSitesList,
  create: IbeSitesCreate,
  edit: IbeSitesEdit,
  icon: FaceIcon,
};
