import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

const localhostPort = 49386;

export const imageApi = {
  upload,
  remove,
};

function upload(request) {
  const url = `${apiConfig.url}${apiConfig.path}/images/upload`;
  //const url = `http://localhost:${localhostPort}/api/images/upload`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}

function remove(request) {
  const url = `${apiConfig.url}${apiConfig.path}/images/delete`;
  //const url = `http://localhost:${localhostPort}/api/images/delete`;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.getBleudineToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}
