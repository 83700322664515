import * as React from "react";
import {
  Pagination,
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  Filter,
  SearchInput,
  TextInput,
  BooleanInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
  DateField,
  DateInput,
  DateTimeInput,
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { guestStatus } from "../../models/guestStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";

const cmAccountsFilters = [
  <DateInput source="date@_gte" label="From" alwaysOn resettable />,
  <DateInput source="date@_lte" label="To" alwaysOn resettable />,
  <TextInput label="Message" source="message" alwaysOn resettable />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Type"
    source="type"
    reference="e_cm_logging_types"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      id: record.id,
      tenant: record.tenant_id,
      date: record.date,
      type: record.type,
      message: record.message,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["id", "tenant_id", "date", "type", "message"],
    },
    (err, csv) => {
      downloadCSV(csv, "cm_logging");
    }
  );
};

const CMLoggingList = (props) => {
  return (
    <List
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      sort={{ field: "date", order: "Desc" }}
      filters={cmAccountsFilters}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <DateField source="date" label="Date" showTime />
        <TextField source="type" label="Type" />
        <TextField source="message" label="Message" />
      </Datagrid>
    </List>
  );
};

export default CMLoggingList;
