import React, { useContext } from "react";
import { SaveButton } from "react-admin";
import { useFormState } from "react-final-form";
import { useNotify, useRedirect } from "react-admin";
import { DataProviderContext } from "react-admin";
import { fromPromise } from "@apollo/client";
import { imageApi } from "../../api/imageApi";
import { uuid } from "../../utils/uuid";

const CustomSaveButton = (formProps) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  var request = {
    ...values
  };

  const handleCreateClick = () => {
    setSaving(true);

    var promises = [];
    formProps.images?.forEach(function (img) {
      if (img.data != null && img.data.length > 0 && img.data[0].data_url.includes("data")) {
        promises.push(
          imageApi.upload({
            file: img.data[0].data_url.split(",")[1],
            fileName: uuid.create_UUID()
          }).then(
            (data) => {
              request[img.name] = data.imageUrl;
            },
            (error) => {
              console.log(error);
            })
        );
      } else if (img.data != null && img.data.length > 0) {
        request[img.name] = img.data[0].data_url;
      }
    });

    Promise.all(promises).then(() => {
      dataProvider
        .create(formProps.resource, { data: { ...request } })
        .then(({ data }) => {
          redirect(`/ibe_sites`);
          notify("Ibe created");
        })
        .catch((error) => {
          setSaving(false);
          notify(error);
        });
    });
  };

  const handleUpdateClick = () => {
    setSaving(true);
    var promises = [];
    formProps.images?.forEach(function (img) {
      if (img.data != null && img.data.length > 0 && img.data[0].data_url.includes("data")) {
        promises.push(
          imageApi.upload({
            file: img.data[0].data_url.split(",")[1],
            fileName: uuid.create_UUID()
          }).then(
            (data) => {
              request[img.name] = data.imageUrl;
            },
            (error) => {
              console.log(error);
            })
        );
      } else if (img.data != null && img.data.length > 0) {
        request[img.name] = img.data[0].data_url;
      }
    });

    Promise.all(promises).then(() => {
      dataProvider
        .update(formProps.resource, { id: request.id, data: { ...request } })
        .then(({ data }) => {
          redirect(`/ibe_sites`);
          notify("Ibe updated");
        })
        .catch((error) => {
          setSaving(false);
          notify(error);
        });
    });
  };

  return (
    <SaveButton
      {...formProps}
      saving={saving}
      disabled={saving}
      onSave={values.id != undefined ? handleUpdateClick : handleCreateClick}
    />
  );
};

export default CustomSaveButton;
