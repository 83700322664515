import gql from "graphql-tag";

const cmLog = gql`
  {
    id
    message
    date
    type
    tenant_id
    e_cm_logging_type {
      id
      comment
    }
  }
`;

export const GET_LIST_CM_LOGGING = cmLog;
