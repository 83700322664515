import React, { useContext } from 'react';
import {
  SaveButton
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import { imageApi } from "../../api/imageApi";
import { uuid } from "../../utils/uuid";
import moment from "moment";

const CustomSaveButton = (formProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useContext(DataProviderContext);
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);

  //massage data
  var request = {
    ...values,
  }

  const handleUpdateClick = () => {
    setSaving(true);
    if (formProps.record.image_url != null) {
      var splitExImg = formProps.record.image_url.split("/");
      imageApi.remove({
        fileName: splitExImg[splitExImg.length - 1]
      }).then(
        (data) => { },
        (error) => {
          console.log(error);
        })
    }

    var promises = [];
    var errors = [];
    let uploadedImage = null;

    if (formProps != null && formProps.images != null){
        formProps.images.forEach(function (img) {
            if (img != null && img.data_url != null && img.data_url.includes("data")) {
              promises.push(
                imageApi.upload({
                  file: img.data_url.split(",")[1],
                  fileName: uuid.create_UUID()
                }).then(
                  (data) => {
                      uploadedImage = data.imageUrl;
                  },
                  (error) => {
                    console.log(error);
                  })
              );
            } else {
              uploadedImage = img?.data_url;
            }
          });
    }
    
    Promise.all(promises).then(() => {
      request.image_url = uploadedImage;
      request.updated_at = moment.utc();
      dataProvider.update(formProps.resource, { id: request.id, data: { ...request } })
        .then(({ data }) => {
          redirect(`/disbursements`);
          notify("Disbursement updated");
        })
        .catch(error => {
          setSaving(false);
          notify(error);
        })
    })

  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={handleUpdateClick} />;
};

export default CustomSaveButton;