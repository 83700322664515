import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ReferenceInput,
    SelectInput,
    TextInput,
    FunctionField,
    downloadCSV,
    AutocompleteInput,
    ReferenceField,
    ShowButton,
    Show,
    SimpleShowLayout,
    RichTextField,
    DateField,
    ImageField,
    DateInput,
    DateTimeInput,
} from 'react-admin';
import { disbursementStatus } from "../../models/disbursementStatus";
import Chip from '@material-ui/core/Chip';
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import jsonExport from 'jsonexport/dist';
import moment from "moment";
import { formatter } from "../../utils/formatter";

const filters = [
    <ReferenceInput
        source="tenant_id"
        reference="tenants"
        // filter={{ tenant_id: authInfo?.tenant?.id }}
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({
            name: searchText,
        })}
        label="Tenant Name"
        alwaysOn
        resettable
    >
        <AutocompleteInput
            optionText={(choice) =>
                choice.id != null ? `${choice.name}` : ""
            }
            options={{
                InputProps: { autoComplete: "no" },
            }}
        />
    </ReferenceInput>,
    <ReferenceInput
        style={{ width: 250 }}
        label="Status"
        source="status"
        reference="e_disbursement_status"
        alwaysOn>
        <SelectInput optionText="comment" />
    </ReferenceInput>,
    <DateInput
        label="Date From"
        source="created_at@_gte"
        alwaysOn>
    </DateInput>,
    <DateInput
        label="Date To"
        source="created_at@_lte"
        resettable
        alwaysOn>
    </DateInput>
];

const exporter = records => {
    const forExport = records.map(record => {
        return {
            tenant: record.tenant.name,
            currency: record.currency,
            amount: record.amount,
            charges: record.charges,
            payable_amount: record.payable_amount,
            image_url: record.image_url,
            bank_name: record.bank_name,
            bank_account_no: record.bank_account_no,
            remark: record.remark,
            status: record.e_disbursement_status.comment,
            created_at: record.created_at,
            updated_at: record.updated_at,
        };
    });
    jsonExport(forExport, {
        headers: ['tenant', 'currency', 'amount', 'charges', 'payable_amount', "bank_name", "bank_account_no", "remark", 'status', "created_at", "updated_at"]
    }, (err, csv) => {
        downloadCSV(csv, 'disbursements');
    });
};

const DisbursementList = props => (
    <List sort={{ field: 'created_at', order: 'Desc' }} filters={filters} bulkActionButtons={false} exporter={exporter} {...props}>
        <Datagrid>
            <FunctionField sortBy="disbursment_no" label="Disbursement No" render={(record) => formatter.disbursementNo(record.disbursment_no)} />
            <TextField source="tenant.name" label="Tenant" />
            <FunctionField sortBy="amount" label="Amount" render={(record) => formatter.formatMoney(record.amount, record.currency, 2)} />
            <FunctionField sortBy="charges" label="Charges" render={(record) => formatter.formatMoney(record.charges, record.currency, 2)} />
            <FunctionField sortBy="payable_amount" label="Payable Amount" render={(record) => formatter.formatMoney(record.payable_amount, record.currency, 2)} />
            <TextField source="bank_name" label="Bank Name" />
            <TextField source="bank_account_no" label="Bank Account No" />
            <TextField source="remark" label="Remark" />
            <FunctionField sortBy="status" label="Status" render={record => <Chip label={record.e_disbursement_status?.comment} style={{ color: 'white', background: disbursementStatus.getColor(record.e_disbursement_status?.id) }} />} />
            <FunctionField sortBy="created_at" label="Created Date" render={(record) => moment(record.created_at).format("DD MMM YYYY hh:mm:ss A")} />
            <FunctionField sortBy="updated_at" label="Last Modified Date" render={(record) => record.updated_at != null ? moment(record.updated_at).format("DD MMM YYYY hh:mm:ss A") : ""} />
            <EditButton style={{ float: "right" }} basePath="/disbursements" />
        </Datagrid>
    </List>
);

export default DisbursementList;
