import React from "react";
import { Edit } from "react-admin";
import IbeSitesForm from "./IbeSitesForm";
import { useTranslate } from "react-admin";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const CompanyTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("ra.label.properties")} {record ? `${record.name}` : ""}
    </span>
  );
};

const IbeSitesEdit = (props) => (
  <div>
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{
        borderRadius: 5,
        background: "white",
        padding: 12,
        border: "1px solid #e0e0e3",
        //marginLeft: 16,
        //marginRight: 30,
        marginTop: 12,
      }}
    >
      <Link color="inherit" href="/ibe_sites">
        IBE Sites
      </Link>
      <Link
        style={{ fontWeight: "bold" }}
        color="textPrimary"
        aria-current="page"
        disabled
      >
        IBE Site
      </Link>
    </Breadcrumbs>
    <Edit title={<CompanyTitle />} {...props} component="div">
      <IbeSitesForm />
    </Edit>
  </div>
);

export default IbeSitesEdit;
