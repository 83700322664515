const isLocalOrDev = window.location.href.includes("localhost") || window.location.href.includes("dev");
const isStaging = window.location.href.includes("stg");
const hasuraSecret = isLocalOrDev ? "2drH5MsdDIsNzxlhdEIAuioyNuJtktQLxhhnLuGEqH11QWsBz0q5ce0dgqXVSCU5" : isStaging ? "gS0WpS6Fgl0FFFNbTAWO1CT5K5c1qOLNqqhH2iss7jsPwo2cnzyb52QpA4dYwWHf" : "Pp1pqMRPjzGlaCsjnShpmIenTdewdsUrfTzMW2TAI5E7o4baJ2zTmGHoZf3lVrDR";
const hasuraUrl = isLocalOrDev ? "https://hasura.dev.bleustay.com/v1/graphql" : isStaging ? "https://hasura.stg.bleustay.com/v1/graphql" : "https://hasura.bleustay.com/v1/graphql";

export const apiConfig = {
  url: window.location.href.includes("localhost") || window.location.href.includes("dev") ? "https://api.dev.bleudine.com" :
  window.location.href.includes("stg") ? "https://api.stg.bleudine.com" : "https://api.bleudine.com",
  path: "/api",
  rideumHotelUrl: window.location.href.includes("localhost") || window.location.href.includes("dev") ? "https://hasura.dev.bleustay.com/v1/graphql" :
    window.location.href.includes("stg") ? "https://hasura.stg.bleustay.com/v1/graphql" : "https://hasura.bleustay.com/v1/graphql",

  getBleudineToken: function () {
    const user = JSON.parse(localStorage.getItem("bleudine-user"));
    return user ? user.token : "";
  },
  getRideumToken: function () {
    const token = localStorage.getItem("token");
    return token ? token : "";
  },
  hasuraSecret: hasuraSecret,
  hasuraUrl: hasuraUrl,
};
