import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import buildHasuraProvider from "ra-data-hasura";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import {authProvider} from "./utils/authProvider"
import { createBrowserHistory as createHistory } from "history";
import themeReducer from "./themeReducer";
import customRoutes from "./routes";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { englishMessages } from "./i18n/ra-language-english";
import { indonesianMessages } from "./i18n/ra-language-indonesian";
import { Logout, Login, Layout } from "./resources/layout";
import Dashboard from "./resources/components/Dashboard";
import disbursements from "./resources/disbursement";
import "./App.css";
import customBuildFields from "./customBuildFields";
import { apiConfig } from "./api/apiConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import icLogo from "./images/ic_logo_text.png";
import tenants from "./resources/tenant";
import tenant_groups from "./resources/tenant_groups";
import user from "./resources/user";
import ibeSites from "./resources/ibeSites";
import cmLogging from "./resources/cmLogging";

const App = () => {
  const history = createHistory();
  const messages = { id: indonesianMessages, en: englishMessages };

  const [dataProvider, setDataProvider] = useState(null);
  const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);

  useEffect(() => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));

    const hasuraAuth = new ApolloClient({
      uri: apiConfig.rideumHotelUrl,
      cache: new InMemoryCache(),
    });

    if (authInfo == null && window.location.pathname != "/login") {
      window.location.href = "/login";
    }

    const checkAuth = () => {
      const tokenResponse = localStorage.getItem("token");
      initHasuraAuth(tokenResponse);
      // authProvider
      //   .getJWTToken()
      //   .then((tokenResponse) => {
      //     localStorage.setItem("token", tokenResponse);
      //     initHasuraAuth(tokenResponse);
      //   })
      //   .catch((error) => {
      //     initHasuraAuth();
      //   });
    };

    const initHasuraAuth = (token) => {
      const hasuraAuth = new ApolloClient({
        uri: apiConfig.hasuraUrl,
        cache: new InMemoryCache(),
        headers: token
          ? {
            "x-hasura-admin-secret": apiConfig.hasuraSecret,
            }
          : {},
      });

      const buildDataProvider = async () => {
        const dataProvider = await buildHasuraProvider(
          { client: hasuraAuth },
          { buildFields: customBuildFields }
        );
        setDataProvider(() => dataProvider);
      };

      buildDataProvider();
    };

    checkAuth();
  }, []);

  if (!dataProvider)
    return (
      <div
        style={{ position: "absolute", marginLeft: "50vw", marginTop: "50vh" }}
      >
        <img
          src={icLogo}
          style={{
            marginTop: -100,
            width: 200,
            display: "block",
            marginBottom: 50,
            marginLeft: -75,
          }}
        />
        <CircularProgress />
        <div style={{ marginTop: 10, marginLeft: -10, fontWeight: "bold" }}>
          Loading
        </div>
      </div>
    );

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      history={history}
      dashboard={Dashboard}
      loginPage={Login}
      logoutButton={Logout}
    >
      <Resource
        name="disbursements"
        options={{ label: "ra.label.disbursements" }}
        list={disbursements.list}
        edit={disbursements.edit}
      />
      <Resource name="services" />
      <Resource name="meals" />
      <Resource name="cancellation_policies" />
      <Resource name="e_countries" />
      <Resource name="e_nationalities" />
      <Resource name="e_genders" />
      <Resource name="e_titles" />
      <Resource name="e_document_types" />
      <Resource name="e_customer_types" />
      <Resource name="e_booking_status" />
      <Resource name="e_booking_sources" />
      <Resource name="e_room_types" />
      <Resource name="e_bed_types" />
      <Resource name="e_mattress_types" />
      <Resource name="e_room_facilities" />
      <Resource name="e_bathroom_facilities" />
      <Resource name="e_view_facilities" />
      <Resource name="e_bedroom_facilities" />
      <Resource name="e_entertainment_facilities" />
      <Resource name="e_kitchen_facilities" />
      <Resource name="e_outdoor_facilities" />
      <Resource name="e_floor_facilities" />
      <Resource name="e_room_status" />
      <Resource name="e_accommodation_status" />
      <Resource name="e_order_status" />
      <Resource name="e_invoice_status" />
      <Resource name="room_type_rates" />
      <Resource name="payments" />
      <Resource name="e_payment_types" />
      <Resource name="e_payment_status" />
      <Resource name="e_deposit_types" />
      <Resource name="e_deposit_status" />
      <Resource name="e_refund_types" />
      <Resource name="e_refund_status" />
      <Resource name="e_room_type_status" />
      <Resource name="e_room_rate_status" />
      <Resource name="e_guest_status" />
      <Resource name="e_company_status" />
      <Resource name="e_disbursement_status" />
      <Resource name="invoice_payments" />
      <Resource
        name="users"
        options={{ label: "ra.label.users" }}
        list={user.list}
        create={user.create}
        edit={user.edit}
      />
      <Resource
        name="tenants"
        options={{ label: "ra.label.properties" }}
        list={tenants.list}
        create={tenants.create}
        edit={tenants.edit}
      />

      <Resource
        name="tenant_groups"
        options={{ label: "ra.label.property_group" }}
        list={tenant_groups.list}
        edit={tenant_groups.edit}
      />
      <Resource name="e_tenant_status" />
      <Resource name="e_user_roles" />
      <Resource name="e_user_status" />
      <Resource name="locales" />
      <Resource name="e_priority_levels" />
      <Resource name="tenant_groups" />

      {/* <Resource
        name="cm_logging"
        options={{ label: "ra.label.cm_logging" }}
        list={cmLogging.list}
      /> */}

      <Resource name="e_cm_logging_types" />

      <Resource
        name="ibe_sites"
        options={{ label: "ra.label.ibe_sites" }}
        list={ibeSites.list}
        create={ibeSites.create}
        edit={ibeSites.edit}
      />
    </Admin>
  );
};

export default App;
